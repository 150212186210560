import React, { Component } from "react";
//import ReactDOM from 'react-dom';
import {
  Form,
  Button,
  Alert,
  Container,
  Row,
  Col,
}
from "react-bootstrap";
import axios from "axios";
import {Link} from "gatsby";
//import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';
import SectionHeading from "./sectionHeading";

export default class ApplicationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendButton: {
        message: 'Apply',
        disabled: false
      },
      alert: {
        success: false,
        error: false
      },
      //captchaToken:undefined,
      emailFocus: false
    };
  }

  resetForm() {
    this.setState({
      sendButton: {
        message: 'Apply',
        disabled: false
      }
    });
  }

  componentDidMount() {
    //loadReCaptcha('6LfH774UAAAAAKGMRIXnvjnT2Ma9iyWHMhX65yk4');
    //console.log("===ContactForm.componentDidMount()===")
  }

  // When the user clicks in the email/phone field
  handleEmailFocus() {
    this.setState({ emailFocus: true })

    // Call the Google reCAPTCHA script
    // This starts a two minute ttl for the token
    // Called here instead of in handleSubmit because it needs time to return before submitting the form
    //  const recaptcha = 
    //    <ReCaptcha
    //      sitekey="6LfH774UAAAAAKGMRIXnvjnT2Ma9iyWHMhX65yk4"
    //      action='contact_form_entry'
    //      verifyCallback={this.verifyCallback}
    //    />;
    //  ReactDOM.render(recaptcha,document.getElementById('contactForm'));
  }

  //  // Save the reCAPTCHA token in the form state
  //  verifyCallback = (callbackToken) => {
  //    // Here you will get the final recaptchaToken!!!  
  //    //console.log(recaptchaToken, "<= your recaptcha token")
  //    this.setState({captchaToken:callbackToken});
  //  }

  handleSubmit = async(event) => {
    event.persist(); // for logging
    event.preventDefault();
    this.setState({
      sendButton: { message: 'Sending...', disabled: true },
      formLastInspection: 'no answer',
      formRoofAge:'no answer',
    });
    console.log(event.target);

    try {
      const params = {
        formCgFormId: 'je34mlb',
        formEmail: event.target.feedbackFormEmail.value,
        formPhone: event.target.feedbackFormPhone.value,
        formWho: event.target.feedbackFormWho.value,
        formWhat: event.target.feedbackFormWhat.value,
        //formWhat: `Location: ${event.target.feedbackFormWhat.value} \n LastInspect:${event.target.feedbackFormWhen.value} \n RoofAge:${event.target.feedbackFormAge.value}`
        //captchaToken: this.state.captchaToken
      };
      await axios.post(`https://3blwbf5x4f.execute-api.us-east-1.amazonaws.com/prod/contact`, params);
      console.log('success!');
      this.setState({ sendButton: { message: 'Sent!', disabled: true }, alert: { success: true, error: false } });
    }
    catch (err) {
      console.log('error!');
      console.log(err);
      this.setState({ sendButton: { message: 'Oops!', disabled: true }, alert: { success: false, error: true } });
    }

    // The code below worked except it scrolled too far...
    //document.getElementById('feedbackSendAlert').scrollIntoView();

    // Reset send button
    setTimeout(function() { this.resetForm(); }.bind(this), 5000);
  }

  render() {
    return (
      <div className="cg-sectionClear">
        <div className="cg-section">
        
          {/* indentation problem here?? */}
        <div id="feedbackForm" style={{padding:'0.5rem 2rem 2rem 2rem', maxWidth:'40rem', margin:'2rem auto 0 auto'}}>
            <Container fluid>
              <Row>
                <Col>
                  <SectionHeading>Roof Inspection</SectionHeading>
                </Col>
              </Row>
              <Row>
                <Col style={{textAlign:'left', fontSize:'14pt',paddingTop:'3rem',paddingBottom:'1rem'}}>
                  <p>If your roof was damaged, <strong>how long would it be until you noticed</strong>?</p>
                  
                  <p>Winter ice can damage a roof. Left undetected, it can quickly lead to other problems, like rot and mold. By the time wet spots appear on your ceiling, there could already be thousands of dollars worth of damage. </p>
                   
                  <p>You can <strong>prevent more damage</strong> by having a professional inspection. </p>
                  
                  <p>With the snow gone, spring is a good time to have your roof inspected. You'll see how your roof held up against the winter ice.</p>
                  
                  <p>Apply for a <strong>free roof inspection</strong>* below.</p>
                </Col>
              </Row>
              <Row>
                <Col style={{textAlign:'left', fontSize:'smaller',paddingBottom:'3rem'}}>
                  <p>* Roof inspection normally valued at $100. Only owners of residential properties in Utica, Rome, New Hartford, and surrounding areas may apply. Applications will be accepted at the discretion of MLB Contracting LLC. </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4>Free Roof Inspection</h4>
                  <p><strong>Application</strong></p>
                </Col>
              </Row>
              <Row>
                <Col style={{textAlign:'left'}}>
                  <Form onSubmit={event => this.handleSubmit(event)}>
                    
                    <Form.Group controlId="feedbackFormWho">
                      <Form.Label srOnly='true'>Name</Form.Label>
                      <Form.Control placeholder="Your Name" />
                    </Form.Group>
                    
                    <Form.Group controlId="feedbackFormEmail">
                      <Form.Label srOnly='true'>Email</Form.Label>
                      <Form.Control placeholder="Email address" />
                    </Form.Group>
                    
                    <Form.Group controlId="feedbackFormPhone">
                      <Form.Label srOnly='true'>Phone</Form.Label>
                      <Form.Control placeholder="Phone" />
                    </Form.Group>
                    
                    <Form.Group controlId="feedbackFormWhat">
                      <Form.Label srOnly='true'>Location</Form.Label>
                      <Form.Control as="textarea" rows="3" placeholder="Address of the property"/>
                    </Form.Group>
                    
                    <Form.Group controlId="feedbackFormWhen">
                      <Form.Label>When was the roof last inspected?</Form.Label>
                      <Form.Check 
                        type='radio'
                        id='lastInspection-1'
                        name='lastInspection'
                        label="I don't know"
                      />
                      <Form.Check 
                        type='radio'
                        id='lastInspection-2'
                        name='lastInspection'
                        label="A year ago or less"
                      />
                      <Form.Check 
                        type='radio'
                        id='lastInspection-3'
                        name='lastInspection'
                        label="More than one year ago"
                      />
                      <Form.Check 
                        type='radio'
                        id='lastInspection-4'
                        name='lastInspection'
                        label="It has never been inspected"
                      />
                    </Form.Group>
                    
                    <Form.Group controlId="feedbackFormAge">
                      <Form.Label>How old is the roof?</Form.Label>
                      <Form.Check 
                        type='radio'
                        id='roofAge-1'
                        name='roofAge'
                        label="I don't know"
                      />
                      <Form.Check 
                        type='radio'
                        id='roofAge-2'
                        name='roofAge'
                        label="5 years or less"
                      />
                      <Form.Check 
                        type='radio'
                        id='roofAge-3'
                        name='roofAge'
                        label="Between 5 and 15 years"
                      />
                      <Form.Check 
                        type='radio'
                        id='roofAge-3'
                        name='roofAge'
                        label="Over 15 years"
                      />
                    </Form.Group>
                    <Button type="submit" 
                        disabled={this.state.sendButton.disabled}
                        
                        className='cg-dark'
                        style={{margin:'2rem auto', display:'block'}}
                        >
                      {this.state.sendButton.message}
                    </Button>
                  </Form>
                  
                  <span id='feedbackSendAlert'>
                    <Alert variant='success' show={this.state.alert.success}>
                      <p>Your application has been sent!</p>
                      <p>Find out more: <Link to='/roofing-contractor' className='cg-linkDark'>Roofing at MLB Contracting</Link></p>
                    </Alert>
                    <Alert variant='warning' show={this.state.alert.error}>
                      Oops! The message could not go though.  Please 
                      email <a href="mlb_contracting@yahoo.com">mlb_contracting@yahoo.com</a> or  
                      call <a href="tel:+13157946223">(315)&nbsp;794&#8209;6223</a>. 
                    </Alert>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col style={{textAlign:'left', fontSize:'smaller'}}>
                  <p>By submitting this application you are allowing MLB Contracting to contact you. Your personal information will be safe - it will never be shared or sold.</p>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

import React from "react";
//import { useState, useEffect } from "react"; // JSONData at runtime
import SEO from "../components/seo";
import ApplicationForm from "../components/applicationForm";
import Layout from "../components/layout";
import Background2 from "../components/background2";
import '../components/reviews.css';
import { useStaticQuery, graphql } from "gatsby";
const Reviews = () => {
    const data = useStaticQuery(graphql`
    query {
      feature1: file(relativePath: { eq: "roof_ice_1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }`);

  return (
  <Layout>
    <SEO title="Roof Inspection" path='special' />

    <Background2 fluid={data.feature1.childImageSharp.fluid}>
      <main id="landingPage">
        <ApplicationForm />
      </main>
    </Background2>
  </Layout>
  )
};

export default Reviews;

import React from 'react'
import PropTypes from "prop-types"
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection2 = ({ fluid, tag, className , backgroundColor, children }) => 
        <BackgroundImage
          Tag={tag}
          className={className}
          fluid={fluid}
          backgroundColor={backgroundColor}
        >
          {children} 
        </BackgroundImage>

const StyledBackgroundSection2 = styled(BackgroundSection2)`
  width: 100%;
  background-position: top right;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
`

BackgroundSection2.defaultProps = {
  tag: 'section',
  backgroundColor: '#333',
}

BackgroundSection2.propTypes = {
  fluid: PropTypes.object.isRequired,
  tag: PropTypes.string,
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
}

export default StyledBackgroundSection2